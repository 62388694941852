<template>
	<div><iframe id="iframe" src="http://wx.dxt10026.com/server/page/enroll/auditList.jsp" style="height: 800px; width: 100%;" frameborder="0"></iframe></div>
</template>

<script>
export default {
	name: 'serial',
	data() {
		return {};
	},
	created() {},
	methods: {}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
