<template>
	<div class="form-box">
		<el-form :model="form" ref="form" label-width="100px">
			<el-form-item prop="value" v-for="(item, index) in form.phones" label="充值" :key="item.key">
				<el-input type="text" size="small" v-model="item.label" placeholder="充值号码" style="width: 260px;margin-right: 10px;"></el-input>
				<el-input
					type="text"
					size="small"
					v-if="item.value == -1"
					v-model="item.amount2"
					placeholder="请输入自定义金额"
					style="width: 140px;margin-right: 10px;"
				></el-input>
				<el-select
					size="small"
					style="width: 140px"
					v-else
					v-model="item.value"
					filterable
					clearable
					placeholder="请选择充值金额"
					class="handle-select mr10"
					@change="labelChange"
				>
					<el-option v-for="item in rechargeAmountList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" icon="el-icon-remove-outline" @click="deleteDomain(index)">减少</el-button>
				<el-button size="small" icon="el-icon-circle-plus-outline" v-if="index == 0" type="primary" :class="[theme]" @click="addDomain">新增</el-button>
			</el-form-item>
			<el-form-item prop="type" label="充值类型">
				<el-radio-group v-model="form.type" @change="typeChange">
					<el-radio label="wxpay" value="wxpay">微信</el-radio>
					<el-radio label="alipay" value="alipay">支付宝</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="扫码支付" v-show="payUrl">
				<div id="qrcode" ref="qrcode" style="position: relative;"></div>
				<div><img src="../assets/img/wxpay.png" v-if="wxpayUrl" alt="" style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" /></div>
				<div><img src="../assets/img/alipay.png" v-if="alipayUrl" alt="" style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" /></div>
			</el-form-item>
		</el-form>
		<span class="dialog-footer" style="display: flex;flex-direction: row;margin-left: 100px;">
			<el-button size="small" :disabled="enabled" type="primary" :class="[theme]" @click="addRecordLabel('form')">生成二维码</el-button>
		</span>
	</div>
</template>
<script>
import { woniuPay, queryOrder, getenabled } from '../api/tag.js';
import QRCode from 'qrcodejs2';

export default {
	name: 'company',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				companyId: '',
				userId: localStorage.getItem('user'),
				rechargetype: -1
			},
			tableData: [],
			form: {
				phones: [
					{
						value: '',
						label: ''
					}
				],
				type: 'wxpay',
				facility: 'pc'
			},
			clearInterval: null,
			rechargeList: [{ value: 0, label: '全部' }, { value: 1, label: '已处理' }, { value: -1, label: '未处理' }],
			rechargeAmountList: [
				{ value: 10, label: '10' },
				{ value: 20, label: '20' },
				{ value: 30, label: '30' },
				{ value: 50, label: '50' },
				{ value: 100, label: '100' },
				{ value: 200, label: '200' },
				{ value: 500, label: '500' },
				{ value: -1, label: '自定义金额' }
			],
			addVisible: false,
			payUrl: false,
			wxpayUrl: false,
			alipayUrl: false,
			enabled: false
		};
	},

	created() {
		this.getenabled();
	},
	methods: {
		getenabled() {
			getenabled().then(res => {
				console.log(res.data, 'dddd');
				if (res.data === false) {
					this.enabled = true;
					this.$message.error('此通道在维护中');
					window.open('https://10040.snailmobile.com/chargecenter/qcharge2.html', '_self');
				} else {
					this.enabled = false;
				}
			});
		},
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		addDomain() {
			this.form.phones.push({
				value: '',
				label: ''
			});
		},
		deleteDomain(index) {
			if (this.form.phones.length <= 1) {
				return;
			} else {
				this.$refs.qrcode.innerHTML = '';
				this.wxpayUrl = false;
				this.alipayUrl = false;
				this.form.phones.splice(index, 1);
			}
		},

		addRecordLabel() {
			this.$refs.qrcode.innerHTML = '';
			this.form.phones.forEach(item => {
				console.log(item);
				if (item.value == -1) {
					item.value = item.amount2;
				}
			});
			this.wxpayUrl = false;
			this.alipayUrl = false;
			woniuPay(this.form).then(res => {
				if (res.code == 200) {
					this.payUrl = true;
					this.payData = res.data;

					if (this.form.type == 'wxpay') {
						this.wxpayUrl = true;
						this.qr = new QRCode('qrcode', {
							width: 130,
							height: 130, // 高度
							text: this.payData.url, // 二维码内容
							colorDark: '#000000',
							colorLight: '#ffffff'
						});
					} else if (this.form.type == 'alipay') {
						this.alipayUrl = true;
						this.qr = new QRCode('qrcode', {
							width: 130,
							height: 130, // 高度
							text: this.payData.qrCode, // 二维码内容
							colorDark: '#000000',
							colorLight: '#ffffff'
						});
					}
					this.clearInterval = setInterval(res => {
						let data = {
							orderId: this.payData.out_trade_no
						};
						queryOrder(data).then(res => {
							if (res.code == 200) {
								if (res.data == true) {
									this.$message.success('支付成功');
									this.$refs.qrcode.innerHTML = '';
									this.payUrl = false;
									this.$router.go(0);
									clearInterval(this.clearInterval);
								}
							} else {
								this.$message.error(res.message);
							}
						});
					}, 2000);
				} else {
					this.$message.error(res.message);
				}
			});
		},
		typeChange(e) {
			this.$refs.qrcode.innerHTML = '';
			this.form.type = e;
			this.payUrl = false;
		},
		labelChange() {
			this.$refs.qrcode.innerHTML = '';
			this.wxpayUrl = false;
			this.alipayUrl = false;
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover {
	background: #66b1ff;
} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.form-box {
	width: 100%;
	height: 100vh;
	padding-top: 20px;
	background-color: #fff;
}
</style>
