<template>
	<div><iframe id="iframe" src="https://www.yuque.com/yunqitong/qouqgx/kgq3xu" style="height: 800px; width: 100%;" frameborder="0"></iframe></div>
</template>

<script>
import { getFindOperationManual } from '../api/operationManual.js';

export default {
	name: 'serial',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			tableData: [],
			urlVisible: false,
			iframeURL: ''
		};
	},
	created() {
		this.getData();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getData() {
			getFindOperationManual().then(res => {
				if (res.code == 200) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		toURL(e) {
			this.urlVisible = true;
			this.iframeURL = e;
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover{background: #66b1ff} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
